<template>
	<div id="adhibitionOutletEdit" class="out-let-form el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }">
			<a-card title="门店基本信息" class="mb12">
				<a-row>
					<a-col :span="12">
						<a-form-item label="门店分类">
							<a-select v-model:value="form.class">
								<a-select-option v-for="(item,index) in typeData" :key="index" :value="item.id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="门店名称" name="name">
							<a-input v-model:value="form.name"></a-input>
						</a-form-item>
						<a-form-item label="门店状态">
							<a-radio-group v-model:value="form.outlet_status">
								<a-radio :value="1">启用</a-radio>
								<a-radio :value="0">禁用</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="营业时间">
							<a-time-picker v-model:value="form.start_time" format="HH:mm" placeholder="开始时间"/>
							<a-time-picker v-model:value="form.end_time" format="HH:mm" placeholder="结束时间"/>
						</a-form-item>
						<a-form-item label="门店简介" name="description">
							<a-textarea v-model:value="form.description" :rows="4" />
						</a-form-item>
						<a-form-item label="门店备注">
							<a-textarea v-model:value="form.note" :rows="4" />
						</a-form-item>
					</a-col>
					<a-col :span="12">
						<a-form-item label="选择店长">
							<com-select-user :mutil="true" :value="form.account_ids" @change="e=>form.account_ids = e"></com-select-user>
							<div class="f12">小提示：当选择的店长没有进行微信公众号授权时，将接收不到订单通知。</div>
						</a-form-item>
						<a-form-item label="联系人姓名" name="contact_name">
							<a-input v-model:value="form.contact_name"></a-input>
						</a-form-item>
						<a-form-item label="联系人电话" name="mobile">
							<a-input v-model:value="form.mobile"></a-input>
						</a-form-item>
						<a-form-item label="门店LOGO" class="cover-input">
							<kd-img-select :src="form.logo" @success="(url)=>{ form.logo = url }"></kd-img-select>
						</a-form-item>
						<a-form-item label="图片展示" >
							<kd-img-select :mutil="true" :src="form.images" @success="(url)=>{ form.images = url }"></kd-img-select>
						</a-form-item>
					</a-col>
				</a-row>
			</a-card>
			<a-card title="配送基本设置">
				<a-row>
					<a-col :span="12">
						<a-form-item>
							<template #label>
								<span>是否开启配送限制</span>
								<a-tooltip content="开启后,需要设置配送范围(配送半径),在配送范围内允许下单购买商品,不在配送范围内则不能下单">
									<i class="ri-information-fill"></i>
								</a-tooltip>
							</template>
							<a-radio-group v-model:value="form.is_radius">
								<a-radio :value="1">开启</a-radio>
								<a-radio :value="0">关闭</a-radio>
							</a-radio-group>
						</a-form-item>
						<a-form-item label="门店配送地址">
							<kd-map :value="form.latitude+','+form.longitude" @change="getAddress"></kd-map>
							<div class="f12">地址用户计算用户到自提地址的距离</div>
						</a-form-item>
						<a-form-item label="配送半径" v-if="form.is_radius==1">
							<a-input v-model:value="form.radius" addon-after="米"> </a-input>
						</a-form-item>
					</a-col>
					<a-col :span="12">
					</a-col>
				</a-row>
			</a-card>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-space>
					<a-button type="primary" @click="saveOutlet">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import KdMap from '@/components/public/kd-map.vue'
import comSelectUser from '@/components/miniapp/com-select-user.vue'
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import outletModel from '@/api/addons/outlet';
export default{
	components:{
		KdMap,
		comSelectUser
	},
	setup(){
		const state = reactive({
			form:{
				id:0,
				name:'',
				logo:'',
				note:'',
				radius:'',
				mobile:'',
				address:'',
				latitude:'',
				longitude:'',
				description:'',
				outlet_status:1,
				is_radius:0,
				account_ids:'',
				start_time:'',
				end_time:'',
				images:[],
				class:'',
				city_name:'',
				area_name:'',
				contact_name:'',
				citycode:'',
				province_name:''
			},
			typeData:[],
		})
		const opt = useRoute().query
		if( opt.id ) outletModel.getOutletDetail(opt.id,Object.keys(state.form),res=>{
			state.form = res
		})

		outletModel.getOutlet(res=>state.typeData = res)
		const saveOutlet = ()=>outletModel.addOrEditOutlet(state.form)

		function getAddress(e){
			//门店经纬度是反的
			state.form.longitude = e.latitude
			state.form.latitude = e.longitude
			state.form.province_name = e.province
			state.form.city_name = e.city
			state.form.area_name  = e.district
			state.form.citycode  = e.citycode
			state.form.address = e.address
		}
		return{
			...toRefs(state),
			getAddress,
			saveOutlet
		}
	}
}
</script>

<style lang="scss">
.kd-outlet-dialog{

	&-user{
		width: 100%;
		display: flex;
		flex-wrap:wrap;
		
		&-lo{
			display: flex;
			width: 150px;
			font-size: 12px;
			margin-top: 10px;
			border: 1px solid #F4F4F4;
			margin-right: 10px;
			align-items: center;
			padding-left: 5px;
			cursor: pointer;
			
			.lo-pic{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}
	}
}
</style>
